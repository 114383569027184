<button (menuOpened)="onMenuOpen()"
        [matMenuTriggerFor]="colorMenu"
        [ngClass]="selectedPalette + '-' + selectedHue"
        class="mat-elevation-z1"
        mat-icon-button>
  <mat-icon>palette</mat-icon>
</button>

<mat-menu #colorMenu="matMenu" class="pristine-material-color-picker-menu mat-elevation-z8">

  <header [ngClass]="selectedColor?.class || 'accent'" class="mat-elevation-z4"
          fxLayout="row" fxLayoutAlign="space-between center">

    <button (click)="goToPalettesView($event)"
            [style.visibility]="view === 'hues' ? 'visible' : 'hidden'"
            aria-label="Palette"
            class="secondary-text" mat-icon-button>
      <mat-icon class="s-20">arrow_back</mat-icon>
    </button>

    <span *ngIf="selectedColor?.palette">
            {{selectedColor.palette}} {{selectedColor.hue}}
        </span>

    <span *ngIf="!selectedColor?.palette">
            Select a Color
        </span>

    <button (click)="removeColor($event)"
            aria-label="Remove color"
            class="remove-color-button secondary-text"
            mat-icon-button
            matTooltip="Remove color">
      <mat-icon class="s-20">delete</mat-icon>
    </button>
  </header>

  <div [ngSwitch]="view" class="views">

    <div *ngSwitchCase="'palettes'" class="view">

      <div class="colors" fxLayout="row wrap" fxLayoutAlign="start start" pristinePerfectScrollbar>
        <div (click)="selectPalette($event, color.key)" *ngFor="let color of (colors | keys)"
             [class.selected]="selectedPalette === color.key"
             [ngClass]="color.key"
             class="color"
             fxLayout="row"
             fxLayoutAlign="center center">
        </div>
      </div>
    </div>

    <div *ngSwitchCase="'hues'" class="view">
      <div class="colors" fxLayout="row wrap" fxLayoutAlign="start start" pristinePerfectScrollbar>
        <div (click)="selectHue($event, hue)" *ngFor="let hue of hues" [class.selected]="selectedHue === hue"
             [fxHide]="selectedPalette === 'pristine-white' && hue !== '500' || selectedPalette === 'pristine-black' && hue !== '500'"
             [ngClass]="selectedPalette + '-' + hue"
             class="color"
             fxLayout="row"
             fxLayoutAlign="center center">
        </div>
      </div>
    </div>

  </div>
</mat-menu>
