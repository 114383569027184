import {Pipe, PipeTransform} from "@angular/core";
import * as _ from "lodash";


@Pipe({
  name: 'filter_byKey'
})
export class FilterByKey implements PipeTransform{
  transform(items: any[], value: string,key:string): any[] {
    if (!items) {
      return [];
    }
    if (!value) {
      return items;
    }
    return _.filter(items, field => {
      if(field[key]==null)
        return false;
      else
        return field[key].toLowerCase().indexOf(value.toLowerCase()) > -1
    })
  }
}




