import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {RouterModule, Routes} from '@angular/router';
import {pristineSharedModule} from '../@pristine/shared.module';
import {MatIconModule} from '@angular/material/icon';
import {pristineProgressBarModule, pristineSidebarModule, pristineThemeOptionsModule} from '../@pristine/components';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {NgxSpinnerModule} from 'ngx-spinner';
import {ToastrModule} from 'ngx-toastr';
import {HttpClientModule} from '@angular/common/http';
import {MatButtonModule} from '@angular/material/button';
import {pristineModule} from '../@pristine/pristine.module';
import {pristineConfig} from './pristine-config';
import {LayoutModule} from './layout/layout.module';


const appRoutes: Routes = [
  {
    path: 'pages',
    loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule)
  },
  {
    path: 'fsp&hsp',
    loadChildren: () => import('./main/fsp/fsp.module').then(m => m.FspModule)
  },
  {
    path: 'tsp',
    loadChildren: () => import('./main/tsp/tsp.module').then(m => m.TspModule)
  }
  ,
  {
    path: 'hsp',
    loadChildren: () => import('./main/hsp/hsp.module').then(m => m.HspModule)
  },
  {
    path: 'general',
    loadChildren: () => import('./main/leave/leave.module').then(m => m.LeaveModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./main/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'people',
    loadChildren: () => import('./main/people/people.module').then(m => m.PeopleModule)
  },
  {
    path: 'tada',
    loadChildren: () => import('./main/ta_ta_bill/TADA.module').then(m => m.TADAModule)
  },
  {
    path: 'setup',
    loadChildren: () => import('./main/Setup/Setup.module').then(m => m.SetupModule)
  },
  {
    path: 'inspection',
    loadChildren: () => import('./main/inspection/inspection.module').then(m => m.InspectionModule)
  },
  {
    path: '**',
    redirectTo: '/dashboard/maindashboard'
  }
];


@NgModule({
  declarations: [
    AppComponent,

  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      countDuplicates: true,
      resetTimeoutOnDuplicate: true,
      progressBar: true,
      progressAnimation: 'decreasing',
      closeButton: true,

    }), // ToastrModule added
    NgxSpinnerModule,
    RouterModule.forRoot(appRoutes, {useHash: true}),
    // Material moment date module
    MatMomentDateModule,

    // Material
    MatButtonModule,
    MatIconModule,

    // pristine modules
    pristineModule.forRoot(pristineConfig),
    pristineProgressBarModule,
    pristineSharedModule,
    pristineSidebarModule,
    pristineThemeOptionsModule,


    // App modules
    LayoutModule,
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production})
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule{}
