<div class="demo-sidebar">
  <mat-list>
    <h3 matSubheader>Sidebar Demo</h3>

    <mat-list-item>
      <span>Sidebar Item 1</span>
    </mat-list-item>

    <mat-divider></mat-divider>

    <mat-list-item>
      <span>Sidebar Item 2</span>
    </mat-list-item>

    <mat-divider></mat-divider>

    <mat-list-item>
      <span>Sidebar Item 3</span>
    </mat-list-item>

    <mat-divider></mat-divider>

    <mat-list-item>
      <span>Sidebar Item 4</span>
    </mat-list-item>

    <mat-divider></mat-divider>

    <mat-list-item>
      <span>Sidebar Item 5</span>
    </mat-list-item>

    <mat-divider></mat-divider>

    <mat-list-item>
      <span>Sidebar Item 6</span>
    </mat-list-item>

    <mat-divider></mat-divider>

    <mat-list-item>
      <span>Sidebar Item 7</span>
    </mat-list-item>

    <mat-divider></mat-divider>

    <mat-list-item>
      <span>Sidebar Item 8</span>
    </mat-list-item>

    <mat-divider></mat-divider>

    <mat-list-item>
      <span>Sidebar Item 9</span>
    </mat-list-item>

    <mat-divider></mat-divider>

    <mat-list-item>
      <span>Sidebar Item 10</span>
    </mat-list-item>

    <mat-divider></mat-divider>

    <mat-list-item>
      <span>Sidebar Item 11</span>
    </mat-list-item>

    <mat-divider></mat-divider>

    <mat-list-item>
      <span>Sidebar Item 12</span>
    </mat-list-item>

    <mat-divider></mat-divider>

    <mat-list-item>
      <span>Sidebar Item 13</span>
    </mat-list-item>

    <mat-divider></mat-divider>

    <mat-list-item>
      <span>Sidebar Item 14</span>
    </mat-list-item>

    <mat-divider></mat-divider>

    <mat-list-item>
      <span>Sidebar Item 15</span>
    </mat-list-item>

    <mat-divider></mat-divider>

    <mat-list-item>
      <span>Sidebar Item 16</span>
    </mat-list-item>

  </mat-list>
</div>
