<!-- SIDE PANEL -->
<ng-container *ngIf="!pristineConfig.layout.sidepanel.hidden && pristineConfig.layout.sidepanel.position === 'left'">

  <!-- PANEL CONTENT -->

</ng-container>
<!-- / SIDE PANEL -->

<div id="main">

  <!-- TOOLBAR: Above -->
  <ng-container *ngIf="pristineConfig.layout.toolbar.position === 'above'">
    <ng-container *ngTemplateOutlet="toolbar"></ng-container>
  </ng-container>
  <!-- / TOOLBAR: Above -->

  <div class="container" id="container-1">

    <!-- NAVBAR: Left -->
    <ng-container *ngIf="pristineConfig.layout.navbar.position === 'left'">
      <ng-container *ngTemplateOutlet="leftNavbar"></ng-container>
    </ng-container>
    <!-- / NAVBAR: Left -->

    <div class="container" id="container-2">

      <!-- TOOLBAR: Below fixed -->
      <ng-container *ngIf="pristineConfig.layout.toolbar.position === 'below-fixed'">
        <ng-container *ngTemplateOutlet="toolbar"></ng-container>
      </ng-container>
      <!-- / TOOLBAR: Below fixed -->

      <div [pristinePerfectScrollbarOptions]="{suppressScrollX: true, updateOnRouteChange : true}" class="container"
           id="container-3"
           pristinePerfectScrollbar>

        <!-- TOOLBAR: Below static -->
        <ng-container *ngIf="pristineConfig.layout.toolbar.position === 'below-static'">
          <ng-container *ngTemplateOutlet="toolbar"></ng-container>
        </ng-container>
        <!-- / TOOLBAR: Below static -->

        <!-- CONTENT -->
        <content></content>
        <!-- / CONTENT -->

        <!-- FOOTER: Below static -->
        <ng-container *ngIf="pristineConfig.layout.footer.position === 'below-static'">
          <ng-container *ngTemplateOutlet="footer"></ng-container>
        </ng-container>
        <!-- / FOOTER: Below static -->

      </div>

      <!-- FOOTER: Below fixed -->
      <ng-container *ngIf="pristineConfig.layout.footer.position === 'below-fixed'">
        <ng-container *ngTemplateOutlet="footer"></ng-container>
      </ng-container>
      <!-- / FOOTER: Below fixed -->

    </div>

    <!-- NAVBAR: Right -->
    <ng-container *ngIf="pristineConfig.layout.navbar.position === 'right'">
      <ng-container *ngTemplateOutlet="rightNavbar"></ng-container>
    </ng-container>
    <!-- / NAVBAR: Right -->

  </div>

  <!-- FOOTER: Above -->
  <ng-container *ngIf="pristineConfig.layout.footer.position === 'above'">
    <ng-container *ngTemplateOutlet="footer"></ng-container>
  </ng-container>
  <!-- FOOTER: Above -->

</div>

<!-- SIDE PANEL -->
<ng-container *ngIf="!pristineConfig.layout.sidepanel.hidden && pristineConfig.layout.sidepanel.position === 'right'">

  <!-- PANEL CONTENT -->

</ng-container>
<!-- / SIDE PANEL -->

<!-- QUICK PANEL -->
<pristine-sidebar class="quick-panel" name="quickPanel" position="right">
  <quick-panel></quick-panel>
</pristine-sidebar>
<!-- / QUICK PANEL -->

<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- @ PARTIALS                                                                                            -->
<!-- ----------------------------------------------------------------------------------------------------- -->

<!-- TOOLBAR -->
<ng-template #toolbar>
  <toolbar *ngIf="!pristineConfig.layout.toolbar.hidden"
           [ngClass]="pristineConfig.layout.toolbar.customBackgroundColor === true ? pristineConfig.layout.toolbar.position + ' ' + pristineConfig.layout.toolbar.background : pristineConfig.layout.toolbar.position">
  </toolbar>
</ng-template>
<!-- / TOOLBAR -->

<!-- FOOTER -->
<ng-template #footer>
  <footer *ngIf="!pristineConfig.layout.footer.hidden"
          [ngClass]="pristineConfig.layout.footer.customBackgroundColor === true ? pristineConfig.layout.footer.position + ' ' + pristineConfig.layout.footer.background : pristineConfig.layout.footer.position">
  </footer>
</ng-template>
<!-- / FOOTER -->

<!-- LEFT NAVBAR -->
<ng-template #leftNavbar>
  <pristine-sidebar *ngIf="!pristineConfig.layout.navbar.hidden" [folded]="pristineConfig.layout.navbar.folded"
                    class="navbar-pristine-sidebar"
                    lockedOpen="gt-md"
                    name="navbar">
    <navbar [variant]="pristineConfig.layout.navbar.variant" class="left-navbar"></navbar>
  </pristine-sidebar>
</ng-template>
<!-- / LEFT NAVBAR -->

<!-- RIGHT NAVBAR -->
<ng-template #rightNavbar>
  <pristine-sidebar *ngIf="!pristineConfig.layout.navbar.hidden" [folded]="pristineConfig.layout.navbar.folded"
                    class="navbar-pristine-sidebar"
                    lockedOpen="gt-md"
                    name="navbar"
                    position="right">
    <navbar [variant]="pristineConfig.layout.navbar.variant" class="right-navbar"></navbar>
  </pristine-sidebar>
</ng-template>
<!-- / RIGHT NAVBAR -->
